import React, { useState, useEffect } from 'react';
import { ComposedChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { format } from 'date-fns';


const periods = ['5D', '1M', '6M', 'YTD', '1Y', '2Y', 'MAX'];

const convertDateTimeToDate = (dateTime: string, selectedPeriod: string) => {
  const date = new Date(dateTime);
  switch (selectedPeriod) { 
    case '1D':
      return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    default:
      return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  }
}

function formatDate(dateString: string | number | Date | undefined) {
  if (!dateString) {
    return undefined;
  }
  const date = new Date(dateString);
  return format(date, 'MMM d, yyyy');
}

const StockChart = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<string>('1M');
  const [data, setData] = useState<any>({});
  const [metadata, setMetadata] = useState<any>({});
  const [chartData, setChartData] = useState<any>({});

  const fetchChartData = async () => {
    const response = await fetch(`${process.env.GATSBY_INDEX_DATA_API_URI}/NYSEOVC/chart_data.json`); // TODO update URL /api/index/NYSEOVC/chart_data.json
    const data = await response.json();
    return data;
  }
  
  useEffect(() => {
    fetchChartData()
      .then((data) => {
        setData(data.periods);
        setMetadata(data.metadata);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    setChartData(data[selectedPeriod]);
  }, [selectedPeriod, data]);

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-xl shadow-lg">
      <div className="mb-4">
        <h1 className="text-2xl font-bold text-gray-800">NYSE&reg; OPEN Venture Capital Unicorn&reg; Index</h1>
        <div className="flex items-baseline mt-2 mb-2">
          <span className="text-3xl font-semibold text-gray-900">{chartData && chartData?.data && chartData?.data.length > 0 ? `${chartData?.data[chartData?.data.length - 1]?.value.toLocaleString()}` : '0.00'}</span>
          <div className="ml-2 flex items-center">
            <span className={`px-2 py-1 text-sm font-medium rounded-full ` + (chartData?.changePercent < 0 ? 'bg-[#f93b3b53] text-[#AE2222]' : 'bg-green-200 text-green-700')}>
              {chartData?.changePercent < 0 ? '▼' : '▲'} {chartData?.changePercent}%
            </span>
            <span className={`ml-2 text-sm ` + (chartData?.change > 0 ? 'text-green-700' : 'text-[#AE2222]')}>
              {chartData?.change}
            </span>
          </div>
        </div>
        <p className="text-xs text-gray-500">
          Close: {formatDate(metadata.lastDate)} · {metadata.ticker} · Currency in USD
        </p>
      </div>
      
      <div className="mb-4 flex flex-wrap">
        {periods.map((period) => (
          <button
            key={period}
            onClick={() => setSelectedPeriod(period)}
            className={`px-3 py-1 text-xs font-medium rounded-full mr-2 mb-2 transition-colors duration-200 ${
              selectedPeriod === period
                ? 'bg-gray-600 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            {period}
          </button>
        ))}
      </div>
      
      <div className="w-full px-0 ml-[-20px] my-10">
        <ResponsiveContainer width="100%" height={360}>
          <ComposedChart data={chartData?.data} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
            <defs>
              <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#A3546CB5" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#A3546C8C" stopOpacity={0.1}/>
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} stroke="#DDD" />
            <XAxis 
              dataKey="date" 
              axisLine={{ stroke: '#e0e0e0' }} 
              tickLine={false} 
              tick={{ fill: '#6B7280', fontSize: 10 }}
              tickFormatter={(value) => `${convertDateTimeToDate(value, selectedPeriod)}`}
            />
            <YAxis
              dataKey="value"
              orientation="left"
              axisLine={{ stroke: '#e0e0e0' }}
              tickLine={false}
              tick={{ fill: '#6B7280', fontSize: 10 }}
              tickFormatter={(value) => `${value.toLocaleString()}`}
              domain={['auto', 'auto']}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                border: 'none',
                borderRadius: '6px',
                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                padding: '8px',
                fontSize: '12px',
              }}
              formatter={(value) => [`${value.toLocaleString()}`, 'NYSEOVC']}
              labelFormatter={(label) => `Date: ${formatDate(label)}`}
            />
            <Area 
              type="linear" 
              dataKey="value" 
              fillOpacity={1} 
              fill="url(#colorValue)" 
              isAnimationActive={false}
            />
            <Line
              tooltipType='none'
              type="linear"
              dataKey="value"
              stroke="#A3546C"
              isAnimationActive={false}
              strokeWidth={2}
              dot={false}
              strokeLinecap="round"
              style={{ strokeDasharray: '0 0' }}
              activeDot={{ r: 6, fill: '#A3546C', stroke: '#fff', strokeWidth: 2 }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default StockChart;