import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Button from '../components/Button'
import Layout from '../components/Layout'
import Form from '../components/PerformanceCertificationWaitlistForm'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Seo from '../components/Seo'
import { ChartBarIcon, CurrencyDollarIcon, GlobeAltIcon, MinusSmIcon, PlusSmIcon, PaperClipIcon } from '@heroicons/react/outline'
import UnicornCompaniesLogoCloud from '../components/UnicornCompaniesLogoCloud'
import UnicornMark from '../images/unicorn-mark.svg';
import { FadeIn, FadeInStagger } from '../components/FadeIn'
import StockChart from '../components/Chart'
import Holdings from '../components/Holdings'

export default function UnicornIndex() {
    const features = [
        {
            name: 'Diverse Data',
            description:
                'The index is powered by the OPEN Price - a daily price for private companies that integrates secondary market activity with primary market activity.',
            href: '#',
            icon: CurrencyDollarIcon,
        },
        {
            name: 'Investable Methodology',
            description:
                'The NYSE® OPEN Venture Capital Unicorn® Index is designed specifically with investible products in mind - enabling passive investment in  venture capital.',
            href: '#',
            icon: GlobeAltIcon,
        },
        {
            name: 'Institutional Administration',
            description:
                'The NYSE® OPEN Venture Capital Unicorn® Index is administered and calculated externally by ICE Data Indices, LLC.',
            href: '#',
            icon: ChartBarIcon,
        },
    ]

    const index_info = [
        {name: 'Index Ticker', value: 'NYSEOVC' },
        {name: 'Calculation Frequency', value: 'Daily' },
        {name: 'Base date', value: '19 March 2021' },
        {name: 'Base level', value: '1,000.00' },
        {name: 'Reconstitution Frequency', value: 'Annual' },
        {name: 'Index Administrator', value: 'ICE Data Indices, LLC' },
        {name: 'Index Calculation Agent', value: 'ICE Data Indices, LLC' },

    ];

    const stats = [
        { id: 1, name: 'Index Constituents', value: '50' },
        { id: 2, name: 'Top 5 Concentration', value: '35%' },
        { id: 3, name: 'Rebalance', value: '6 Month' },
    ]

    return (
        <Layout>
            <Seo title="NYSE® OPEN Venture Capital Unicorn® Index" description='The NYSE® OPEN Venture Capital Unicorn® Index is a modified market capitalization-weighted index designed to track the 50 largest U.S. venture capital (“VC”) backed private companies with valuations of $1 billion or greater.' />

            <div className="relative px-6 lg:px-8 overflow-hidden">
                <div className="absolute transform-gpu overflow-hidden blur-3xl z-0" aria-hidden="true">
                    <div
                        className="left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-primary to-accent opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem] h-inherit animate-background-hero"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <div className="absolute inset-x-0 top-[calc(100%-13rem)] transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)] z-0" aria-hidden="true">
                    <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary to-accent opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <FadeIn>
                    <div className="mx-auto max-w-2xl py-12 sm:py-24 lg:py-24 z-100">
                        <div className="mb-8 flex justify-center">
                            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-primary ring-1 ring-primary font-bold">
                                NYSE® OPEN Venture Capital Unicorn® Index
                            </div>
                        </div>
                        <div className="text-center">
                            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                The definitive benchmark for venture capital.
                            </h1>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                            The NYSE® OPEN Venture Capital Unicorn® Index is a rules-based, modified market capitalization-weighted private company share benchmark designed to track the 50 largest U.S. venture capital (“VC”) backed private companies with valuations of $1 billion or greater.
                            </p>
                        </div>
                    </div>
                </FadeIn>
            </div>

            <div className="max-w-7xl mx-auto py-10">
                <div className="grid grid-cols-3 gap-4">
                <div className="md:col-span-2 col-span-3">
                        <StockChart />
                    </div>
                <div className="md:col-span-1 col-span-3">
                    <div className="p-6 bg-white rounded-xl shadow-lg">
                      <div className="mb-4">
                        <h1 className="text-2xl font-bold text-gray-800">Index Information</h1>
                        <table className="min-w-full divide-y divide-gray-300">
                            <tbody className="divide-y divide-gray-200">
                            {index_info.map((info) => (
                                <tr key={info.name}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                      {info.name}                  
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{info.value}</td>
                                </tr>
                ))}
                            </tbody>
                        </table>
                        
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <FadeInStagger>
                <div className="bg-white py-16 sm:py-24">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <FadeIn>
                            <div className="mx-auto max-w-2xl lg:text-center">
                                <h2 className="text-base font-semibold leading-7 text-primary">About</h2>
                                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                    Your go-to lens for private markets.
                                </p>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    The rise of unicorns has dominated private markets as companies stay private longer. Until now, investors have lacked appropriate benchmarks to measure the performance of these companies.
                                </p>
                            </div>
                        </FadeIn>
                        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                                {features.map((feature) => (
                                    <FadeIn key={feature.name}>
                                        <div className="flex flex-col">
                                            <dt className="flex items-center gap-x-3 text-lg font-semibold leading-7 text-gray-900">
                                                <feature.icon className="h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                                {feature.name}
                                            </dt>
                                            <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                                                <p className="flex-auto">{feature.description}</p>
                                            </dd>
                                        </div>
                                    </FadeIn>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div>
            </FadeInStagger>

            
            <FadeInStagger>
                <Holdings />
            </FadeInStagger>

            <FadeInStagger>
                <div className="bg-white py-16 sm:py-24">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <FadeIn>
                            <div className="mx-auto max-w-2xl lg:text-center">
                                <h2 className="text-base font-semibold leading-7 text-primary">Downloads & Links</h2>
                            </div>
                        </FadeIn>
                        <FadeIn>
                        <div className="mx-auto mt-16 max-w-2xl lg:max-w-none">
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dd className="mt-2 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                                  <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                      <div className="flex w-0 flex-1 items-center">
                                        <PaperClipIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                          <span className="truncate font-medium">Index Rules and Methodology</span>
                                        </div>
                                      </div>
                                      <div className="ml-4 flex-shrink-0">
                                        <a target="_blank" href="/files/NYSEOVC_methodology_2024_08_14.pdf" className="font-medium text-indigo-600 hover:text-indigo-500">
                                          Download
                                        </a>
                                      </div>
                                    </li>
                                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                      <div className="flex w-0 flex-1 items-center">
                                        <PaperClipIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                          <span className="truncate font-medium">Press Release: ICE and OPEN Introduce New Benchmark for Tracking Large U.S. Venture-Backed Unicorns</span>
                                        </div>
                                      </div>
                                      <div className="ml-4 flex-shrink-0">
                                        <a target="_blank" href="https://ir.theice.com/press/news-details/2024/ICE-and-OPEN-Introduce-New-Benchmark-for-Tracking-Large-U.S.-Venture-Backed-Unicorns/default.aspx" className="font-medium text-indigo-600 hover:text-indigo-500">
                                          View
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </dd>
                            </div>
                        </div>
                        </FadeIn>
                    </div>
                </div>
            </FadeInStagger>


            <FadeInStagger>
                <div className="bg-primary bg-cover bg-[url('../images/raster-bg-alone-2x.png')]" id="form">
                    <div className="mx-auto max-w-7xl px-6 py-20 sm:py-24 lg:flex lg:items-center lg:justify-between lg:px-8">
                        <FadeIn>
                            <h2 className="text-white text-3xl font-bold tracking-tight sm:text-4xl">
                                Interested?
                                <br />
                                Request an index fact sheet.
                            </h2>
                        </FadeIn>
                        <FadeIn>
                            <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
                                <form name="unicorn-index-factsheet-cta" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/index-factsheet-form-success" className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6 w-full">
                                    <input type="hidden" name="form-name" value="unicorn-index-factsheet-cta" />
                                    <input className="hidden" name="bot-field" />
                                    <input type="hidden" id="gclid_field" name="gclid_field" value=""></input>
                                    <div className="grid grid-cols-1 md:col-span-2 gap-6 w-full justify-self-end">
                                        <input className="bg-transparent appearance-none border border-gray-100 w-full py-2 px-4 text-white focus:outline-none focus:bg-white/10 focus:border-accent placeholder-white/80 h-12" type="text" name="name" required placeholder="Name" />
                                    </div>
                                    <div className="grid grid-cols-1 md:col-span-2 gap-6 w-full justify-self-end">
                                        <input className="bg-transparent appearance-none border border-gray-100 w-full py-2 px-4 text-white focus:outline-none focus:bg-white/10 focus:border-accent placeholder-white/80 h-12" type="text" name="company" required placeholder="Company" />
                                    </div>
                                    <div className="grid grid-cols-1 md:col-span-2 gap-6 w-full justify-self-end">
                                        <input className="bg-transparent appearance-none border border-gray-100 w-full py-2 px-4 text-white focus:outline-none focus:bg-white/10 focus:border-accent placeholder-white/80 h-12" type="email" name="email" required placeholder="Business Email" />
                                    </div>
                                    <div className="grid grid-cols-1 md:col-span-2 gap-6">
                                        <Button classes='text-white px-3 sm:px-16'>Request fact sheet</Button>
                                    </div>
                                </form>
                            </div>
                        </FadeIn>
                    </div>
                </div>
            </FadeInStagger>



        </Layout >
    )
}